<template>
  <div
    class="container-step7 flex flex-col flex-1 items-center justify-center pt-10"
  >
    <div class="flex flex-col mb-10 lg:mb-6">
      <div
        class="font-medium text-dark-grey-400 mb-2 font-poppins text-5xl lg:text-2xl"
      >
        Nous recherchons des offres parmi :
      </div>
      <div class="v-slider-frame mx-auto">
        <ul class="v-slides">
          <li class="v-slide">Promoteurs partenaires</li>
          <li class="v-slide">Constructeurs</li>
          <li class="v-slide">Lotisseurs</li>
          <li class="v-slide">Aménageurs</li>
          <li class="v-slide">Marchands de biens</li>
        </ul>
      </div>
    </div>
    <div class="relative" v-if="isActive">
      <MapFrance />
      <AnimatedProgressCircle />
    </div>
  </div>
</template>
<script>
import { Elastic } from 'gsap'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isActive(is_active) {
      if (is_active) {
        setTimeout(() => {
          this.$gsap.set('.land,.hexagon,.container-loader', {
            visibility: 'visible',
          })

          var vsOpts = {
            $slides: document.getElementsByClassName('v-slide'),
            $list: document.getElementsByClassName('v-slides'),
            duration: 1,
            lineHeight: 50,
          }

          var vSlide = this.$gsap.timeline({
            onComplete: () => {
              document.querySelector('.suivant-tunnel').click()
            },
            paused: true,
            repeat: 0,
          })

          vSlide.from(
            '.container-loader',
            { opacity: 0, duration: 1 },
            'feature',
          )
          vSlide.to(
            '.animate-precent',
            {
              innerText: 100,
              duration: 5,
              snap: {
                innerText: 1,
              },
            },
            'feature',
          )

          for (var i = 0; i < vsOpts.$slides.length; i++) {
            vSlide.to(
              vsOpts.$list,
              2,
              {
                y: i * -1 * vsOpts.lineHeight,
                ease: Elastic.easeOut.config(1, 0.4),
              },
              'feature+=' + i * 1.6,
            )
          }

          for (var i = 0; i < 95; i++) {
            vSlide.to(
              '.dept' + i,
              2,
              { fill: '#433C4E', stroke: '#FFFFFF' },
              'feature+=' + i / 16,
            )
          }

          vSlide.to('.land', 0.5, { fill: '#433C4E', stroke: '#FFFFFF' })

          vSlide.play()
        }, 500)
      }
    },
  },
  mounted() {},
}
</script>
<style lang="scss">
.container-step7 {
  background-image: url('/images/building-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 5rem;
}

.v-slider-frame {
  @apply bg-blue-ice-400 px-2;
  height: 50px;
  width: fit-content;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
}
ul.v-slides {
  list-style-type: none;
  transform: translateY(50px);
  padding: 0;
}
.v-slide {
  @apply text-white font-medium font-poppins;
  font-size: 24px;
  line-height: 50px;
}
</style>
